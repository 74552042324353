import React, { useState } from 'react';
import logo from '../assets/images/fins_logo.svg';
import { useNavigate , Link } from 'react-router-dom';
import UserProfile from './UserProfile';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Optionally, notify the backend to invalidate the session
            const response = await fetch('https://user.finscanner.ai/finscanner-admin-api/api/logout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Remove token from local storage
                localStorage.removeItem('token');

                // Redirect to the login page or home page
                navigate('/login');
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className="app-header">
                <div className="app-header__logo">
                    <div className="logo">
                        <Link to="/">
                            <img src={ logo } alt="FinScanner AI Solutions" className="logo-with-name" />
                        </Link>
                    </div>
                    <div className="header__pane">
                        <div className="hamburger">
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div className="hamburger">
                        <li className="user-sec d-flex justify-content-center align-items-center">
                            <div className="user-sec-profile drop-down-hover">
                                <div className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown"
                                    data-bs-auto-close="true" aria-expanded="false">
                                    <img src="assets/images/nav-avatar.jpg" alt="avtar_img" className="avtar_img" />
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                    <li>
                                        <button onClick={togglePopup}><i className="fa-solid fa-user"></i> My Profile</button>
                                    </li>
                                    <li>
                                        <a href="templates/profile.html#password-sec"><i className="fa-solid fa-key"></i>Change Password</a>
                                    </li>
                                    <li>
                                        <button onClick={handleLogout}><i className="fa fa-sign-out"></i>Log Out</button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </div>
                </div>
                <div className="hamburger-sidebar">
                    <button className="minsidebar">
                        <i className="fa-solid fa-less-than"></i>
                    </button>
                </div>
                <div className="app-header__content">
                    <div className="app-header-right">
                        <ul className="nav-right">
                            <li className="request-demo-sec">
                                <Link to="https://calendly.com/finscanner/30min" rel="noreferrer" target="_blank" className="nav-right-icon" title="Request Demo">
                                    <i className="fa-solid fa-file-video"></i>
                                </Link>
                            </li>
                            <li className="help-sec">
                                <Link to="/" className="nav-right-icon" title="Help">
                                    <i className="fa-solid fa-circle-question"></i>
                                </Link>
                            </li>
                            <li className="not-sec">
                                <Link to="/" id="notificationLink" className="nav-right-icon notificationLink" title="Notification">
                                    <i className="fa-solid fa-bell" aria-hidden="true"></i>
                                    <span className="badge">3</span>
                                </Link>
                            </li>
                            <li className="user-sec">
                                <div className="user-sec-profile drop-down-hover">
                                    <div className="dropdown-toggle" type="button" id="defaultDropdown"
                                        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                        <img src="assets/images/nav-avatar.jpg" alt="avtar_img" className="avtar_img" />
                                        <UserProfile />
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                        <li><Link to="/forgot-password"><i className="fa-solid fa-key"></i> Change Password</Link></li>
                                        <li><button onClick={handleLogout}><i className="fa fa-sign-out"></i>Log Out</button></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
