import React from 'react';
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { UserProvider } from './components/UserContext'
import './assets/libraries/fontawesome/css/all.min.css';
import Header from './components/Header';
import SidebarNav from './components/SidebarNav';
import LoginPage from './pages/LoginPage';
import Dashboard from './components/Dashboard';
import Documents from './pages/Documents';
import API from './pages/ApiPage';
import Integration from './pages/Integration';
import NotFound from './components/NotFound';
import ForgotPassword from './components/ForgotPassword';


import SignUpPage from './pages/SignUpPage';

function ProtectedRoute({ children }) {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? 
    <>
      <Header />    
      <section className="main-body">
        <>
          <SidebarNav />
        </>
        <>
          { children }
        </>
      </section> 
    </> : 
    <>
      <Navigate to="/login" replace />
    </>
}

function AuthRedirect({ children }) {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : 
  <>
    {children}
  </>;
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<AuthRedirect> <UserProvider><LoginPage /> </UserProvider></AuthRedirect>} />
        <Route path="/sign-up" element={<AuthRedirect><SignUpPage /></AuthRedirect>} />
        <Route path="/forgot-password" element={<AuthRedirect><ForgotPassword /></AuthRedirect>} />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/documents" element={<ProtectedRoute><Documents /></ProtectedRoute>} />
        <Route path="/api" element={<ProtectedRoute><API /></ProtectedRoute>} />
        <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
        <Route path="/integration" element={<ProtectedRoute><Integration /></ProtectedRoute>} />
      </Routes>
    </Router>
  )
}
