import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="modal-dialog login" id="myforgotpass">
                <div className="modal-content">
                    <div className="modal-header modal-head">
                        <button type="button" className="btn-close close-btn" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="row">
                        <div className="mb-4 text-center login-box">
                            <a href="/dashboard.html" className="img-link">
                                <img src="assets/images/fins_logo.svg" alt="logo-img" width="200" className="mb-2 fins-logo" />
                            </a>
                        </div>
                    </div>
                    <form className="login-form">
                        <div className="row gy-3 gy-md-4 overflow-hidden">
                            <div className="form-inp">
                                <input type={showPassword ? "text" : "password"} className="form-control mail-inp" name="email" id="email" placeholder="Enter Email" required="" />
                                <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} className="login-icon-img">
                                    <i id="eyeIcon" className="fa fa-eye"></i>
                                </span>
                            </div>
                        </div>
                        <div className="signin-btn d-flex justify-content-center">
                            <div className="cta_btn button-1 mt-5"><button type='submit'>Reset Password</button></div>
                        </div>
                        <div className="mt-4 text-center">
                            <Link to="/login" className="btn-sign-span cursor-pointer d-flex justify-content-center gap-2">
                                <i className="fas fa-arrow-alt-circle-left"></i>
                                <h6>Back to Login</h6>
                            </Link>
                        </div>
                        <div className="mt-4 text-center loggin-link">
                            <span className="text-center text-decoration-none btn-sign">Don&apos;t have an account? <Link to="/sign-up" className="btn-sign-span cursor-pointer"> Sign Up</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
