import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SignupForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showrePassword, setshowrePassword] = useState(false);
  const [error, setError] = useState('');
  const [redirectedUrl, setRedirectedUrl] = useState('');
  const [content, setContent] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const toggleRePasswordVisibility = () => {
    setshowrePassword(!showrePassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== reEnterPassword) {
      setError('Password and re-entered password do not match.');
    } else {
      setError('');
      console.log('Current Password:', password);
      console.log('New Password:', reEnterPassword);
    }

    const userData = {
      name: name,
      email: email,
      password: password
    };
    try {
      const response = await fetch('https://user.finscanner.ai/finscanner-admin-api/api/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      // Check for 301 or 302 status codes
      if (response.status === 301 || response.status === 302) {
        const redirectUrl = response.headers.get('/dashboard');
        if (redirectUrl) {
          window.location.href = redirectUrl; // Perform the client-side redirect
        } else {
          console.error('Redirect URL not found in headers');
        }
      } else if (response.ok) {
        // Handle success case
        console.log('Signup successful');
        window.location.href = '/dashboard'; // Redirect to /dashboard
      } else {
        console.error('Signup failed');
      }
      // if (response.status === 301 || response.status === 302) {
      //   const redirectedUrl = response.headers.get('/dashboard'); // Get the redirection URL
      //   setRedirectedUrl(redirectedUrl); // Set redirected URL in state
      //   console.log('Redirecting to:', redirectedUrl);

      //   // Follow the redirection manually
      //   const redirectedResponse = await fetch(redirectedUrl);
      //   const data = await redirectedResponse.text(); // assuming the content is text/HTML
      //   setContent(data); // Store the content of the redirected page
      // } else {
      //   const data = await response.json(); // Or handle the response as needed
      //   setContent(JSON.stringify(data));
      // }

      // const data = await response.json();
      
      // if (response.ok) {
      //   console.log('Signup successful:', data);
      // } else {
      //   console.error('Signup failed:', data);
      // }

    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <>
      <div className="modal-dialog login" id="mySignup">
        <div className="modal-content">
          <div className="row">
            <div className="mb-4 text-center login-box">
              <a href="https://finscanner.ai/" target="_blank" rel="noopener noreferrer" className="img-link">
                <img src="assets/images/fins_logo.svg" alt="logo-img" width="200" className="mb-2 fins-logo" />
              </a>
            </div>
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="row gy-3 gy-md-4 overflow-hidden">
              <div className="form-inp">
                <input 
                  className="form-control mail-inp"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                />
              </div>
              <div className=" mt-2 form-inp">
                <input
                  className="form-control mt-3  mail-inp"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-inp">
                <div className="inp-sec">
                  <input
                    id="txtPassword"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="form-control pass-inp"
                    required
                  />
                  <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} className="login-icon-img">
                    <i id="eyeIcon" className="fa fa-eye"></i>
                  </span>
                </div>
              </div>
              <div className="form-inp">
                <div className="inp-sec">
                  <input
                    id="txtRePassword"
                    type={showrePassword ? "text" : "password"}
                    value={reEnterPassword}
                    onChange={(e) => setReEnterPassword(e.target.value)}
                    placeholder="Re-enter Password"
                    className="form-control pass-inp"
                    required
                  />
                  <span onClick={toggleRePasswordVisibility} style={{ cursor: 'pointer' }} className="login-icon-img">
                    <i id="eyeIcon" className="fa fa-eye"></i>
                  </span>
                </div>
              </div>
                <div className="signin-btn d-flex justify-content-center">
                  <div className="cta_btn button-1">
                    <button type="submit">Sign up</button>
                  </div>
                </div>
            </div>
          </form>
          <div className="mt-4 text-center loggin-link">
            <span className="text-center text-decoration-none btn-sign">Already have an account? <Link to="/login" className="btn-sign-span cursor-pointer"> Log In</Link></span>
          </div>
        </div>
      </div>
    </>
  );
};