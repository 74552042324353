import React ,{ useState, useEffect, useRef  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const AllTable = () => (
    <table id="file-info" className="table all" >
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle " type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">2</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">02-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">202.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">3</Link></td>
                <td><Link to="/" title="" className="">invoice 3</Link></td>
                <td><Link to="/" title="" className=""> 54563</Link></td>
                <td><Link to="/" title="" className="">p 54563</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">03-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">203.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">4</Link></td>
                <td><Link to="/" title="" className="">invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54564</Link></td>
                <td><Link to="/" title="" className="">p 54564</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">04-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">204.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
        </tbody>
    </table>
    
);
  
const ApprovedTable = () => (
    <table id="file-info2" className="table approved">
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">6</Link></td>
                <td><Link to="/" title="" className="">invoice 6</Link></td>
                <td><Link to="/" title="" className=""> 54566</Link></td>
                <td><Link to="/" title="" className="">p 54566</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">06-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">206.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
        </tbody>
    </table>
);

const InReviewTable = () => (
    <table id="file-info3" className="table review">
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">Invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">7</Link></td>
                <td><Link to="/" title="" className="">invoice 7</Link></td>
                <td><Link to="/" title="" className=""> 54567</Link></td>
                <td><Link to="/" title="" className="">p 54567</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">07-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">207.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">Invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
                
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">7</Link></td>
                <td><Link to="/" title="" className="">invoice 7</Link></td>
                <td><Link to="/" title="" className=""> 54567</Link></td>
                <td><Link to="/" title="" className="">p 54567</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">07-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">207.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
);

const RejectTable = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const response = await axios.get('https://66e24e8f494df9a478e15d79.mockapi.io/User', {
              headers: {
                'Authorization': 'Bearer YOUR_API_TOKEN', // Replace with your API token
              },
            });
            setUserData(response.data);
            setLoading(false);
          } catch (err) {
            setError(err.message);
            setLoading(false);
          }
        };
    
        fetchUserData();
      }, []);
    
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    return (
        <>
        <table id="file-info4" className="table rejected">
            <thead>
                <tr>
                    <th>#</th>
                    <th><Link to="/" title="File Name">File Name</Link></th>
                    <th><Link to="/" title="Document ID">Document ID</Link></th>
                    <th><Link to="/" title="Order number">Order number</Link></th>
                    <th><Link to="/" title="Assign to">Assign to</Link></th>
                    <th><Link to="/" title="Issued at">Issued at</Link></th>
                    <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                    <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                    <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                    <th><Link to="/" title="Status">Status</Link></th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {userData.map((user) => (
                <tr key={user.id}>
                    <td><Link to="/">{user.id}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className=""> {user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="">{user.name}</Link></td>
                    <td><Link to="/" title="" className="reject">Rejected</Link></td>
                    <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                    Edit</Link></li>
                            <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                    Delete</Link></li>
                        </ul>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
        </>
    );
};

export default function Dashboard() {
    const [activeTab, setActiveTab] = useState('tab1');
    const modalRef = useRef(null);

    const openModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
          const modal = new window.bootstrap.Modal(modalElement);
          modal.show();
        } else {
          console.error('Modal element is null');
        }
    };
    
    return (
        <>
            <div className="main-outer">
                <div className="model">
                    <div className="upload-file-sec">
                        <h3><span>Dashboard</span></h3>
                        <div className="btn-sec btn_group">
                            <div className="button-1 cta_btn icon-btn">
                                <Link to="/" onClick={openModal} className="primary-button " data-bs-toggle="modal" data-bs-target="#myModaluploadfile"><i className="fa-solid fa-plus"></i> Upload File</Link>
                            </div>
                            <div className="button-2 cta_btn icon-btn">
                                <Link to="/" className="secondary-button dropdown-toggle drop-down-hover" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"><i className="fa-solid fa-arrow-down"></i>
                                    Download extracted data
                                </Link>
                                <ul className="dropdown-menu download-dropdown" aria-labelledby="defaultDropdown">
                                    <li> <Link to="/" className="dropdown-item">XLSX</Link></li>
                                    <li> <Link to="/" className="dropdown-item">CSV</Link></li>
                                    <li> <Link to="/" className="dropdown-item">XML</Link></li>
                                    <li> <Link to="/" className="dropdown-item">JSON</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="outer">
                    <div className="file-sec">
                        <div className="file-type">
                            <button className={`target-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => setActiveTab('tab1')} >
                                all files<span className="doc_no"></span>(3)
                            </button>
                            <button className={`target-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => setActiveTab('tab2')} >
                                approved<span className="doc_no">(1)</span>
                            </button>
                            <button className={`target-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => setActiveTab('tab3')} >
                                in review<span className="doc_no">(2)</span>
                            </button>
                            <button className={`target-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => setActiveTab('tab4')} >
                                rejected<span className="doc_no">(0)</span>
                            </button>
                        </div>
                        <div className="search-sec">
                            <form className="input-group">
                                <div className="form-outline has-search" data-mdb-input-init="">
                                    <input id="search-sec" type="text" className="form-control form-control-inp" placeholder="Search" />
                                    <button className="form-inp-btn" type="submit">
                                        <span className="fa fa-search form-control-feedback"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className='table-responsive'>
                            {activeTab === 'tab1' && <AllTable />}
                            {activeTab === 'tab2' && <ApprovedTable />}
                            {activeTab === 'tab3' && <InReviewTable />}
                            {activeTab === 'tab4' && <RejectTable />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" role="dialog" id="myModaluploadfile" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="profile-tab" aria-modal="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header modal-head">
                            <h4 className="modal-title">Upload File</h4>
                            <button type="button" className="btn-close close-btn" data-bs-dismiss="modal"></button>
                        </div>
                        <hr className="border-hr" />
                        <div className="modal-body">
                            <div className="file-upload-section">
                                <div className="file-upload-data">
                                    <img src="assets/images/backup.svg" alt="backup-icon" />
                                    <h5>Upload your files directly here</h5>
                                    <div className="file file--upload">
                                        <label className="input-file-label" htmlFor="input-file">
                                            <i className="fa-solid fa-plus"></i> Upload Files
                                        </label>
                                        <input id="input-file" type="file" />
                                    </div>
                                    <div className="upload-from-device">
                                        <Link to="/"><img src="assets/images/google-drive.svg" alt="google-drive" className="google-drive-img" /></Link>
                                        <Link to="/"><img src="assets/images/dropbox.svg" alt="upload" className="dropbox-img" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer upload-sec">
                                <div className="button-2">
                                    <button type="button" className="btn" data-bs-dismiss="modal">Cancel</button>
                                </div>
                                <div className="button-1">
                                    <button type="button" className="btn" data-bs-dismiss="modal">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    )
}
